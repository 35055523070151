.align-self-center {
	align-self: center;
}

.flip {
	transform: rotateY(-180deg);
}

.has-items-bottom {
	align-items: flex-end;
}

.is-in-center {
	display: block;
	margin: 0 auto;
}

.flex-wrap {
	flex-wrap: wrap;
}

.has-flex-1 {
	flex: 1;
}

.has-flex-2 {
	flex: 2;
}

.has-flex-3 {
	flex: 3;
}

.flex-grow {
	flex-grow: 1;
}

.flex-direction-column {
	flex-direction: column;
}

.has-space-between {
	justify-content: space-between;
}

.has-content-centered-vertically {
	align-items: center;
}

.has-content-centered-horizontally {
	justify-content: center;
}

.has-content-centered {
	justify-content: center;
	align-items: center;
}

.has-content-end {
	justify-content: end;
	align-items: end;
}

.has-text-underlined {
	text-decoration: underline;
}

.has-line-height-full {
	line-height: 100%;
}

/* BACKGROUND */

.has-no-background {
	background: none !important;
}

.has-background-covered {
	background-position: center !important;
	background-repeat: no-repeat;
	background-size: cover;
}

.has-background-stripes {
	background-image: url('~assets/images/backgrounds/background_stripes.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.has-background-glitch {
	background-image: url('~assets/images/backgrounds/background_glitch.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.has-overflow-y-auto {
	overflow-x: hidden;
	overflow-y: auto !important;
}

.has-overflow-hidden {
	overflow: hidden !important;
}

.has-hyphens {
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.dont-allow-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.has-overflow-hidden {
	overflow: hidden;
}

/*
	FONT
*/

.has-font-pixel {
	font-family: 'Retro Gaming', cursive;
}

/*
font-family: "BlinkMacSystemFont",
"-apple-system",
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
"Helvetica",
"Arial",
sans-serif
*/

.abbreviate-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

// Normal text that is not just in uppercase
.has-text-standard {
	text-transform: none !important;
}

.has-no-pointer-events {
	pointer-events: none !important;
	cursor: default !important;
}

.has-cursor-pointer {
	cursor: pointer !important;
}

.break-word {
	word-wrap: break-word;
}

.has-z-index-max {
	z-index: 999999 !important;
}

.has-background-blur {
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
}

.is-underlined {
	text-decoration: underline !important;
}

.is-fixed {
	position: fixed;
}

.is-static {
	position: static !important;
}

.is-absolute {
	position: absolute !important;
}

.has-height-auto {
	height: auto;
}

.has-height-half {
	height: 50%;
}

.has-height-one-fifth {
	height: 20%;
}

.has-height-25-percent {
	height: 25%;
}

.has-height-one-third {
	height: 33%;
}

.has-height-two-thirds {
	height: 66%;
}

.has-height-75-percent {
	height: 75%;
}

.has-height-four-fiths {
	height: 80%;
}

/* ====================== */
/*   	  Height    	  */
/* ====================== */


$heights: (
	0,
	3,
	5,
	8,
	10,
	15,
	16,
	20,
	22,
	25,
	30,
	32,
	35,
	40,
	45,
	50,
	55,
	60,
	64,
	65,
	70,
	75,
	80,
	85,
	90,
	95,
	100,
	128,
	130,
	140,
	150,
	160,
	170,
	180,
	190,
	200,
	210,
	220,
	230,
	240,
	256,
	260,
	270,
	280,
	290,
	300,
	310,
	320,
	330,
	340,
	350,
	400,
	500,
	512,
	600,
	700,
);

@each $height in $heights {
	$i: 1;

	.has-height-#{$height} {
		height: $height + px !important;
	}

		.has-max-height-#{$height} {
			max-height: $height + px !important;
		}
}


/* ====================== */
/*     Border Radius      */
/* ====================== */


$radius: (
	0,
	3,
	5,
	10,
	15,
	20,
	22,
	25,
	50,
	100
);

@each $rad in $radius {
	$i: 1;

	.br-#{$rad} {
		border-radius: $rad + px;
	}
}

$radiusTop: (
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	15,
	16,
	20,
	22,
	25,
	50,
	100
);

@each $rad in $radiusTop {
	$i: 1;

	.br-#{$rad}-top {
		border-top-left-radius: $rad + px;
		border-top-right-radius: $rad + px;
	}

	.br-#{$rad}-bottom {
		border-bottom-left-radius: $rad + px;
		border-bottom-right-radius: $rad + px;
	}

	.br-#{$rad}-left {
		border-bottom-left-radius: $rad + px;
		border-top-left-radius: $rad + px;
	}

	.br-#{$rad}-right {
		border-bottom-right-radius: $rad + px;
		border-top-right-radius: $rad + px;
	}
}

.has-no-border {
	border: none !important;
}


/* ====================== */
/*        Min-Width       */
/* ====================== */

$widths: (
	10,
	16,
	20,
	30,
	32,
	40,
	50,
	75,
	100,
	110,
	120,
	125,
	128,
	150,
	175,
	200,
	250,
	270,
	280,
	300,
	350,
	400,
	450,
	500,
	550,
	600,
	700,
	800,

);

@each $width in $widths {
	$i: 1;

	.has-min-width-#{$width} {
		min-width: $width + px !important;
	}
}

/* ====================== */
/*        Max-Width       */
/* ====================== */

$max-widths: (
	10,
	16,
	20,
	25,
	30,
	32,
	35,
	40,
	50,
	60,
	64,
	70,
	75,
	80,
	90,
	100,
	110,
	120,
	125,
	128,
	130,
	140,
	150,
	160,
	170,
	175,
	180,
	190,
	200,
	240,
	250,
	260,
	270,
	280,
	290,
	300,
	350,
	400,
	450,
	500,
	550,
	600,
	650,
	700,
	750,
	800,
	950,
	900,
	950,
	1000,
	1100,
	1200,
	1300,
);

@each $width in $max-widths {
	$i: 1;

	.has-max-width-#{$width} {
		max-width: $width + px !important;
	}

	.has-width-#{$width} {
		width: $width + px !important;
	}
}

.has-max-width-50-percent {
	max-width: 50%;
}

.has-max-width-60-percent {
	max-width: 60%;
}

.has-max-width-70-percent {
	max-width: 70%;
}

.has-max-width-80-percent {
	max-width: 80%;
}

.has-max-width-90-percent {
	max-width: 90%;
}

.has-max-width-100-percent {
	max-width: 100%;
}

/* ====================== */
/*    Margin / Padding    */
/* ====================== */
$sizes: (
	0,
	3,
	5,
	7,
	10,
	12,
	15,
	20,
	25,
	30,
	40,
	50,
	60,
	70,
	80,
	90,
	100,
	110,
	120,
	130,
	140,
	150,
	160,
	180,
	200
);
$positionsShort: (
	"t",
	"l",
	"b",
	"r"
);
$positions: (
	"top",
	"left",
	"bottom",
	"right"
);
@each $size in $sizes {
	$i: 1;

	.m#{$size} {
		margin: $size + px !important;
	}

	.nm#{$size} {
		margin: -$size + px !important;
	}

	.p#{$size} {
		padding: $size + px !important;
	}
	@each $position in $positions {
		$posShort: nth($positionsShort, $i);
		$i: $i+1;

		.m#{$posShort}#{$size} {
			margin-#{$position}: $size + px !important;
		}

		.p#{$posShort}#{$size} {
			padding-#{$position}: $size + px !important;
		}

		.nm#{$posShort}#{$size} {
			margin-#{$position}: -$size + px !important;
		}
	}
}

.has-margin-auto {
	margin: auto;
}

.has-margin-top-auto {
	margin-top: auto !important;
}

.has-margin-left-auto {
	margin-left: auto !important;
}

.has-margin-right-auto {
	margin-right: auto !important;
}

.has-width-auto {
	width: auto;
}

.break-word {
	word-wrap: break-word;
}

.flip {
	transform: scaleX(-1);
}

.cleanButton {
	border: none;
	background: none !important;
	cursor: pointer !important;
	outline: none !important;
}

div .cleanButton:focus {
	outline: 0 !important;
	border: none !important;
	box-shadow: none!important;
}

.has-fullheight {
	height: 100% !important;
}

.has-min-fullheight {
	min-height: 100% !important;
}

.has-max-fullheight {
	max-height: 100% !important;
}

.is-max-fullheight {
	max-height: 100vh !important;
}

.is-max-fullheight-95 {
	max-height: 95vh !important;
}

.is-max-fullheight-80 {
	max-height: 80vh !important;
}

.is-max-fullheight-navbar {
	max-height: calc(100vh - 69px) !important;
}

.has-fullwidth {
	width: 100% !important;
}

.has-maxWidth {
	max-width: 100% !important;
}

.has-hover:hover {
	background-color: $grey-light !important;
}

.is-fullheight {
	height: 100vh !important;
}

.has-min-height-100 {
	min-height: 100px !important;
}

.is-min-fullheight {
	min-height: 100vh !important;
}

.is-min-fullwidth {
	min-width: 100vw !important;
}

.is-fullwidth {
	width: 100vw !important;
}

.has-min-height-550 {
	min-height: 550px;
}

.boxshadow {
	//box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
}

.box {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dont-select-text {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}
