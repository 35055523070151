@import 'colorpalette.scss';

/* ====================== */
/*       Primary       */
/* ====================== */

.has-background-primary {
	color: $white !important;
	background-color: $primary !important;
}

.has-gradient-primary {
	background: linear-gradient(45deg, $primary 0%, rgba(166,236,42,1) 100%);
}

.has-border-primary-glow {
		color: #fff;
		box-shadow: -1px 1px $primary,
			-2px 2px $primary,
			-3px 3px $primary,
			-4px 4px $primary,
			-5px 5px $primary;
}



.has-shadow-primary {
	-webkit-box-shadow: 5px 5px 0px 0px $primary, 5px 5px 15px 5px $primary;
	box-shadow: 5px 5px 0px 0px $primary, 5px 5px 15px 5px $primary;
}

.has-border-right-primary-light {
	border-right: 1px solid $primary;
}

.has-border-right-primary {
	border-right: 2px solid $primary;
}

.has-border-right-primary-bold {
	border-right: 3px solid $primary;
}

.has-border-bottom-primary {
	border-bottom: 2px solid $primary !important;
}

/* ====================== */
/*       Secondary        */
/* ====================== */

.has-background-secondary {
	color: $white !important;
	background-color: $secondary !important;
}

.has-text-secondary {
	color: $secondary !important;
}

.has-border-left-bold-secondary {
	border-left: 3px solid $secondary !important;
}

/* ====================== */
/*       HotScrimps       */
/* ====================== */

.has-background-hotscrimps {
	color: $white !important;
	background-color: $hotScrimps !important;
}

.has-border-hotscrimps-light {
	border: 1px solid $hotScrimps !important;
}

.has-border-hotscrimps {
	border: 2px solid $hotScrimps !important;
}

.has-border-hotscrimps-thick {
	border: 3px solid $hotScrimps !important;
}

.has-text-hotscrimps {
	color: $hotScrimps !important;
}

.switch[type="checkbox"].is-hotscrimps:checked+label::before,
.switch[type="checkbox"].is-hotscrimps:checked+label::before {
	background-color: $hotScrimps;
}

.has-hotscrimps-glow {

	color: #fff;
	box-shadow: 0 0 2px #fff,
	0 0 10px #fff,
	0 0 5px #FF4800,
	0 0 5px #FF4800,
	0 0 10px #FF4800,
	0 0 20px #FF4800 !important;

}

.has-border-right-hotscrimps-light {
	border-right: 1px solid $hotScrimps;
}

.has-border-right-hotscrimps {
	border-right: 2px solid $hotScrimps;
}

.has-border-right-hotscrimps-bold {
	border-right: 3px solid $hotScrimps;
}

/* ====================== */
/*      Playerboard      */
/* ====================== */

$competitive: #de2926;
//$competitive: #e20000;
$just4fun: #02a145;

.has-text-just4fun {
	color: $just4fun !important;
}

.has-text-competitive {
	color: $competitive !important;
}

.has-playerboard-competitive-glow {
	color: #fff;
	box-shadow: 0 0 2px #fff,
		0 0 10px #fff,
		0 0 5px $competitive,
		0 0 5px $competitive,
		0 0 10px $competitive,
		0 0 20px $competitive;
}

.has-playerboard-just4fun-glow {
	color: #fff;
	box-shadow: 0 0 2px #fff,
		0 0 10px #fff,
		0 0 5px $just4fun,
		0 0 5px $just4fun,
		0 0 10px $just4fun,
		0 0 20px $just4fun;
}


.has-playerboard-userentry-glow {
	color: #fff;
	box-shadow: 0 0 2px #fff,
		0 0 10px #fff,
		0 0 5px $playerboard,
		0 0 5px $playerboard,
		0 0 10px $playerboard,
		0 0 20px $playerboard;
}

.has-playerboard-competitive-border {
	color: #fff;
	box-shadow: -1px 1px $competitive,
			-2px 2px $competitive,
			-3px 3px $competitive,
			-4px 4px $competitive,
			-5px 5px $competitive;
}

.has-playerboard-just4fun-border {
	color: #fff;
	box-shadow: -1px 1px $just4fun,
			-2px 2px $just4fun,
			-3px 3px $just4fun,
			-4px 4px $just4fun,
			-5px 5px $just4fun;
}

.has-playerboard-userentry-border {
	color: #fff;
	box-shadow: -1px 1px $playerboard,
			-2px 2px $playerboard,
			-3px 3px $playerboard,
			-4px 4px $playerboard,
			-5px 5px $playerboard;
}

.has-text-playerboard {
	color: $playerboard !important;
}

.has-background-playerboard {
	color: $white  !important;
	background-color: $playerboard !important;
}

.has-border-playerboard-light {
	border: 1px solid $playerboard  !important;
}

.has-border-playerboard {
	border: 2px solid $playerboard  !important;
}

.has-border-top-playerboard {
	border-top: 2px solid $playerboard  !important;
}

.has-border-bottom-playerboard {
	border-bottom: 2px solid $playerboard  !important;
}

.has-border-right-playerboard-light {
	border-right: 1px solid $playerboard;
}

.has-border-right-playerboard {
	border-right: 2px solid $playerboard;
}

.has-border-right-playerboard-bold {
	border-right: 3px solid $playerboard;
}

.has-border-playerboard-thick {
	border: 3px solid $playerboard  !important;
}

.has-text-playerboard {
	color: $playerboard  !important;
}

.switch[type="checkbox"].is-playerboard:checked+label::before,
.switch[type="checkbox"].is-playerboard:checked+label::before {
	background-color: $playerboard;
}


/* ====================== */
/*         Danger         */
/* ====================== */

.has-border-danger-light {
	border: 1px solid $danger;
}

.has-border-danger-top-light {
	border-top: 1px solid $danger;
}

/* ====================== */
/*          Grey          */
/* ====================== */

.has-hover-grey:hover {
	color: $grey !important;
}

.has-border-dotted-grey {
	border: 1px dotted $grey !important;
}

.has-border-dashed-grey {
	border: 1px dashed $grey !important;
}

.has-border-bottom-grey {
	border-bottom: 1px solid $grey !important;
}

.has-border-top-grey {
	border-top: 1px solid $grey  !important;
}

.has-border-left-grey {
	border-left: 1px solid $grey  !important;
}

.has-border-right-grey {
	border-right: 1px solid $grey  !important;
}

.has-border-grey {
	border: 2px solid $grey !important;
}

.has-background-grey-gradient {
	background: rgb(0, 0, 0);
	background: linear-gradient(45deg, #22222e 0%, #3a3a44 100%);
}

/* ====================== */
/*       Grey-Light       */
/* ====================== */

.has-hover-grey-light:hover {
	background-color: $grey-light !important;
}

.has-border-bottom-grey-light {
	border-bottom: 1px solid $grey-light !important;
}

.has-border-top-grey-light {
	border-top: 1px solid $grey-light  !important;
}

.has-border-grey-light {
	border: 1px solid $grey-light !important;
}

/* ====================== */
/*      Grey-Lighter      */
/* ====================== */

.has-hover-grey-lighter:hover {
	background-color: $grey-lighter !important;
}

/* ====================== */
/*    	     White        */
/* ====================== */
.has-border-white-light {
	border: 1px solid $white !important;
}

.has-border-white {
	border: 2px solid $white !important;
}

.has-border-white-thick {
	border: 3px solid $white !important;
}

/* ====================== */
/*    	     Black        */
/* ====================== */
.has-border-black-light {
	border: 1px solid $black !important;
}

.has-border-black {
	border: 2px solid $black !important;
}

.has-border-black-thick {
	border: 3px solid $black !important;
}

.has-background-black-gradient {
	background: rgb(0, 0, 0);
	background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(28, 28, 28, 1) 100%, rgba(28, 28, 28, 1) 100%);
}

/* ====================== */
/*    	     Dark         */
/* ====================== */

.has-border-dark-thick {
	border: 3px solid $dark !important;
}

.has-border-dark {
	border: 2px solid $dark !important;
}

.has-background-dark-with-gradient {
	background: rgb(27, 27, 35);
	background: linear-gradient(180deg, $dark 92%, rgba(84, 179, 61, 0.571848807882528) 100%);
}

/* ====================== */
/*        green dark       */
/* ====================== */

.has-border-green-dark {
	border: 2px solid $greenDark !important;
}