@charset "utf-8";

@import './colorpalette.scss';

// Bulma overrides
$scheme-main: #1B1B23;
$scheme-invert: $white;
/* $body-background-color: #1B1B23;
$body-color: #1B1B23;
$background: #1B1B23; */

$box-background-color: $dark;
$box-radius: 5px;

$navbar-background-color: $dark;
$input-background-color: $dark;
$button-background-color: #6C6B7C;

$control-radius: 5px;
$input-radius: 5px;

$link: $white;
$text: $white;
$text-strong: $white;

$button-border-color: none;
$button-active-color: $white;
$button-focus-color: $white;

$input-background-color: #35353F;
$input-border-color: none;
$input-disabled-background-color: #35353F;

$link-hover: $grey-light;

$radius: 22px;
$radius-large: 26px;



@import "../../node_modules/bulma/bulma.sass";

@import '~bulma-switch';
@import './helper.scss';
@import './colors.scss';
@import './animations.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
// @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


@font-face {
    font-family: 'Retro Gaming';
    src: url('fonts/Retro Gaming.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
} 


@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("fonts/Poppins-Black.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}



// * {
// 	outline: none;
// }
//
// *:focus {
// 	outline: none;
// 	border: none;
// 	box-shadow: none;
// }
//
:focus {
	outline: none;

	//border: none;
}

//
// *:active {
// 	outline: none;
// 	border: none;
// }
//
// :focus {
// 	outline: none;
// }

::-moz-focus-inner {
	border: 0;
}

html, body {
	overflow-x: hidden;
	overflow-y: auto;
	height: auto !important;
}

body {
    //background-image: url('~assets/images/backgrounds/background_glitch.png');
	//background-size: cover;
	//background-repeat: no-repeat;

    min-height: 100vh;
}

body,
button,
optgroup,
select,
h1, h2, h3, h4, h5, h6, p {
	// font-family: 'Retro Gaming', cursive;
	font-family: "Poppins";
	// text-transform: uppercase;
}

/*
     Bulma overwrites
*/

.columns {
	margin: 0 !important;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
     color: $secondary;
}

/**
 POPUP
*/
.popup-background-overlay {
     background-color: $dark;
}


.popup-background {
	position: fixed;
	top: 0;
	left: 0;
	/* background-color: $dark;
	opacity: 0.7; */
	height: 100%;
	width: 100%;
	z-index: 1;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}

.popup-overlay {
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}

.popup-arrow path {
	fill: $box-background-color !important;
}

.no-blur-overlay {
	backdrop-filter: none !important;
	-webkit-backdrop-filter:none !important;
}




/*
	DROPDOWN
*/

#dropdown #trigger {
	// min-width: 280px;
}

.dropdown-content {
	//width: 400px !important;
	max-height: 500px;
	overflow-y: auto;
	border-radius: 5px !important;
	border: 1px solid #ddd !important;
	z-index: 999 !important;
	padding: 0 !important;
}

.dropdown-content button:hover {
	border: 1px solid #ddd !important;
}

.match-overlay {
     background: #707070 !important;
}
