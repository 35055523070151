/*
	Grow Transition
*/

.grow_small {
	transition: transform 0.4s ease;
}

.grow_small:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
	transition: transform 0.4s ease;
}


.grow {
	transition: transform 0.2s ease;
}

.grow:hover {
	-webkit-transform: scale(1.10);
	-ms-transform: scale(1.10);
	transform: scale(1.10);
	transition: transform 0.2s ease;
}

.pull {
	transition: transform 0.2s ease;
}

.pull:hover {
	-webkit-transform: translate(0px, -20px);
	-ms-transform: translate(0px, -20px);
	transform: translate(0px, -20px);
	transition: transform 0.2s ease;
}

@media only screen and (max-width: 1024px) {
	.pull:hover {
     	-webkit-transform: none;
     	-ms-transform: none;
     	transform: none;
     }
}


/*
Fade
*/

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	-webkit-animation: fadeIn 0.3s ease-in;
	-moz-animation: fadeIn 0.3s ease-in;
 	-ms-animation: fadeIn 0.3s ease-in;
  	-o-animation: fadeIn 0.3s ease-in;
	animation: fadeIn 0.3s ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	-webkit-animation: fadeOut 0.3s ease-in;
	-moz-animation: fadeOut 0.3s ease-in;
 	-ms-animation: fadeOut 0.3s ease-in;
  	-o-animation: fadeOut 0.3s ease-in;
	animation: fadeOut 0.3s ease-in;
}

.popup-fade-content {
	-webkit-animation: fadeIn 0.2s ease-in;
	-moz-animation: fadeIn 0.2s ease-in;
 	-ms-animation: fadeIn 0.2s ease-in;
  	-o-animation: fadeIn 0.2s ease-in;
	animation: fadeIn 0.2s ease-in;
}

.overlay-content {
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
}

/*
	bounce
*/

@keyframes bounceOutUp {
  20% {
    opacity: 1;
    transform: translate3d(0, -10px, 0) scaleY(0.985);
  }

  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0) scaleY(3);
  }
}

.bounceOutUp-enter {
	opacity: 0;
}

.bounceOutUp-enter-active {
	-webkit-animation: fadeIn 0.5s ease-in;
	-moz-animation: fadeIn 0.5s ease-in;
 	-ms-animation: fadeIn 0.5s ease-in;
  	-o-animation: fadeIn 0.5s ease-in;
	animation: fadeIn 0.5s ease-in;
}

.bounceOutUp-exit-active {
	-webkit-animation: bounceOutUp 0.6s ease-in;
	-moz-animation: bounceOutUp 0.6s ease-in;
 	-ms-animation: bounceOutUp 0.6s ease-in;
  	-o-animation: bounceOutUp 0.6s ease-in;
	animation: bounceOutUp 0.6s ease-in;
}


/*
	back out
*/

@keyframes backOutDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translateY(700px) scale(0.7);
    opacity: 0.7;
  }
}

.backOutDown-enter {
	opacity: 0;
}

.backOutDown-enter-active {
	-webkit-animation: fadeIn 0.5s ease-in;
	-moz-animation: fadeIn 0.5s ease-in;
 	-ms-animation: fadeIn 0.5s ease-in;
  	-o-animation: fadeIn 0.5s ease-in;
	animation: fadeIn 0.5s ease-in;
}

.backOutDown-exit-active {
	-webkit-animation: backOutDown 0.5s ease-in;
	-moz-animation: backOutDown 0.5s ease-in;
 	-ms-animation: backOutDown 0.5s ease-in;
  	-o-animation: backOutDown 0.5s ease-in;
	animation: backOutDown 0.5s ease-in;
}
