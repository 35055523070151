//$primary: #54ba42;
//$primary: #89ef9a;
$primary: #54B33D;
//$secondary: #b2f422;
$orange: #F27118;
$blue: #086842;
$secondary: #F901EA;
$dark: #27272F;
$light: #F5F5F5;
$grey-light: #B5B5B5;
$black: #000000;
$white: #ffffff;
$hotScrimps: #ff672b;
$playerboard: #3DB2FF;
$greenDark: #3F5518;
